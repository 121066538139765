@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Rubik:wght@400;500;600;700&display=swap");

@import url(https://assets.website-files.com/6465ef4a3ebaf6d1094d9e5b/6466041dee5ad19ad3126185_hero%20circle.png);
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  border: none;
  text-decoration: none;
}
body {
  background-color: #fff;
  font-family: Poppins, sans-serif;
}
.container {
  overflow: hidden;
}
button {
  padding: 14px 24px;
  border-radius: 30px;
  color: black;
  font-size: 17px;
}
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}

/* .hero_section {
  position: relative;
} */
/* .hero_section {
  position: relative;
  background-image: url(https://assets.website-files.com/6465ef4a3ebaf6d1094d9e5b/6466041dee5ad19ad3126185_hero%20circle.png);
  background-position: 100%;
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  margin-top: -56px; 
  padding: 11px 72px;
  height: 100vh;
} */

.nav_content img {
  width: 140px;
  height: 39px;
  margin-right: 20px;
  padding: 0;
}
/* temporary styling */
.about-heading,
.blog-heading,
.contact-heading,
.pricing-heading,
.bookdemo-heading {
  text-align: center;
  color: red;
  text-decoration: underline;
}

#btn {
  background-color: #222;
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s ease;
  padding: 14px 24px;
  border-radius: 30px;
  font-size: 16px;
  color: #fff;
}
#btn:hover {
  background-color: rgb(110, 69, 233);
}
.header-content {
  display: flex;
  align-items: center;
  gap: 35px;
  height: 0;
}
.header-content a {
  color: #222;
  font-weight: 500;
}
.navigation {
  display: flex;
  align-items: center;
}
.sign_dec {
  color: #000;
  font-size: 17px;
}
/* LINK BOX */
.link_box {
  position: absolute;
  top: 9%;
  left: 91%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fff;
  padding: 20px 28px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: all 0.5s ease-in;
}
.navigation-right {
  display: flex;
  align-items: center;
  gap: 10px;
}
.icon {
  border: 1px solid #bbb;
  border-radius: 50%;
  padding: 12px;
  font-size: 46px;
  cursor: pointer;
}

/* ***************************** */
.nav_menu {
  background-color: #000;
  width: 46px;
  left: 46;
  border: none;
  color: #fff;
  display: none;
}
.nav_menu-green {
  background-color: green;
  color: #fff;
}

/*  ******************************** HOME STYLE */
.home {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 19px;
}
.home_content h2 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: 1px;
  margin-bottom: 1.5rem;
  opacity: 1;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(#000 44%, #757575 76%, #dfdfdf);
  -webkit-background-clip: text;
  background-clip: text;
  font-family: Poppins, sans-serif;
  font-weight: 300;
}
.home_content {
  margin-bottom: 3rem;
}
.home_content p {
  line-height: 1.5;
  margin-bottom: 30px;
  font-family: Poppins, sans-serif;
  font-size: 1.125rem;
}
.btn1 {
  font-size: 16px;
  color: #fff;
  text-align: center;
  background-color: #6e45e9;
  font-weight: 500;
  cursor: pointer;
  transition: 0.5s ease;
}
.btn1:hover {
  background-color: #421abb;
}
.btn2 {
  font-size: 16px;
  background-color: #fff;
  border: 1px solid#000;
  margin-left: 20px;
  cursor: pointer;
  transition: 0.5s ease;
}
.btn2:hover {
  background-color: #2c2c2c;
  color: #fff;
}
.specialist {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.specialist img {
  width: 67px;
  height: 67px;
}
.specialist_info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.specialist_info span {
  font-size: 14px;
}
.specialist_info a {
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
  color: #000;
}
.home_img img {
  width: 645px;
  height: 536px;
}

/* ========== -DEMO STYLE-============*/
.demo_section {
  display: grid;
  grid-template-columns: 1.75fr 1fr;
  align-items: center;
  gap: 6.25rem;
  margin: 7rem 0;
  padding: 0px 72px;
}
.demo_logos {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  padding: 1.5rem 0;
}
.demo_logos p {
  color: #fff;
  padding: 30px 40px;
  margin-right: 2rem;
  font-size: 30px;
}

.demo_component {
  position: relative;
  height: 487px;
}
.demo_component video {
  position: absolute;
  top: 0;
  left: 0;
  width: 758px;
  object-fit: cover;
  height: fit-content;

  border-radius: 25px;
  overflow: hidden;
}
.demo_content h1 {
  font-size: 3.5rem;
  letter-spacing: 1px;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 1.1rem;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(#000 51%, #d6d6d6 84%, #c7c7c7);
  -webkit-background-clip: text;
  background-clip: text;
  font-family: Poppins, sans-serif;
  font-weight: 300;
}

.demo_content p {
  font-size: 1.125rem;
  line-height: 1.52;
}
/* ================- INTERGRATE SECTION- ============= */
.section {
  padding: 0 72px;
}
.integrate-heading {
  text-align: center;
  font-size: 3.5rem;
  padding-top: 80px;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(#000 40%, #6b6b6b 72%, #dfdfdf);
  -webkit-background-clip: text;
  background-clip: text;

  font-family: Poppins, sans-serif;
  font-weight: 300;
  margin-bottom: 2rem;
}
.integer-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 12rem;
  padding: 3rem 0;
}
.integer-content img {
  width: 77px;
  margin-bottom: 1rem;
}
.integer-content h5 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(#000 40%, #6b6b6b 72%, #dfdfdf);
  -webkit-background-clip: text;
  background-clip: text;
  font-family: Poppins, sans-serif;
  font-weight: 300;
}
.integer-content span {
  font-size: 1rem;
  line-height: 1.5;
}
/* =================- MINIMIZE-SECTION / business / - ============= */
.minimize_content {
  display: grid;
  grid-template-columns: 1.25fr 1fr;
  align-items: center;
}
.minimize_content span {
  font-size: 2.5rem;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  text-align: left;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(#000 40%, #6b6b6b 72%, #dfdfdf);
  -webkit-background-clip: text;
  background-clip: text;
  font-family: Poppins, sans-serif;
  font-weight: 300;
}
.minimize_content ul {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 1.5rem;
}
.minimize_content ul li {
  display: flex;
  align-items: center;
  column-gap: 9px;
}
.minimize_content img {
  width: 431px;
  height: 409px;
}

/*  - REUSBLE CODE IN MINIMIZE / BUSINESS / CLIENT - */
.markIcon {
  width: 33px;
  height: 32px;
}
.btn-group {
  margin-top: 18px;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.btn-group .btn {
  border: 1px solid #000;
  background-color: transparent;
  border-radius: 200px;
  padding: 12px 24px;
  color: black;
  font-size: 16px;
  font-weight: 500;
  transition: 0.5s ease;
  cursor: pointer;
}
.btn-group .btn:hover {
  background-color: #f1efef;
}
.btn_link {
  display: flex;
  align-items: center !important;
  flex-direction: row;
  gap: 0.5rem;
}
.btn_link a {
  font-size: 16px;
  color: black;
  font-weight: 500;
}
.rightArrow {
  width: 21px;
  height: 21px;
  margin-top: 7px;
}
/*  END - REUSBLE CODE IN MINIMIZE / BUSINESS / CLIENT - */

/* =================-BUSINESS-SECTION / - ============= */
.business_content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.business_content span {
  font-size: 2.5rem;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  text-align: left;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(#000 40%, #6b6b6b 72%, #dfdfdf);
  -webkit-background-clip: text;
  background-clip: text;
  font-family: Poppins, sans-serif;
  font-weight: 300;
}
.business_content ul {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 1.5rem;
}
.business_content ul li {
  display: flex;
  align-items: center;
  column-gap: 9px;
}
.business-img-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.business_content img {
  width: 456px;
  height: 443px;
}

/* =================-CLIENT-SECTION / - ============= */
.client_content {
  display: grid;
  grid-template-columns: 1.25fr 1fr;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.client_content span {
  font-size: 2.5rem;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  text-align: left;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(#000 40%, #6b6b6b 72%, #dfdfdf);
  -webkit-background-clip: text;
  background-clip: text;
  font-family: Poppins, sans-serif;
  font-weight: 300;
}
.client_content ul {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 1.5rem;
}
.client_content ul li {
  display: flex;
  align-items: center;
  column-gap: 9px;
}
.client_img-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.client_content img {
  width: 435px;
  height: 443px;
}
/* =================-RESOURCES-SECTION - ============= */
.resourses-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 64px;
}
.resourses-heading span {
  font-size: 56px;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(#000 40%, #6b6b6b 72%, #dfdfdf);
  -webkit-background-clip: text;
  background-clip: text;
  font-family: Poppins, sans-serif;
  font-weight: 300;
  margin-bottom: 24px;
}
.resourses-heading p {
  font-size: 18px;
}
/* =================-CARD-SECTION / - ============= */
.card_box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 29px;
  row-gap: 43px;
}
.box {
  background-image: linear-gradient(163deg, #fff 66%, #f3f3f3);
  border: 1px solid #e9e9e9;
  border-radius: 15px;
  overflow: hidden;
}
.cardImg {
  width: 100%;
  height: 230px;
  object-fit: cover;
}
.box_content {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
}
.R-btn {
  font-weight: 400;
  font-size: 14px;
  padding: 8px;
  background-color: #e4e4e4;
  border-radius: 200px;
}
.box_content h1 {
  max-height: 4.4rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.4;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.box_content p {
  font-size: 16px;
  height: 49px;
  overflow: hidden;
}
.author {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
}
.author-content {
  display: flex;
  flex-direction: column;
}
.author-content span {
  font-size: 14px;
}
.date {
  display: flex;
  align-items: center;
  gap: 7px;
}
.author img {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
}
/* =================-SECTION-SECTION / - ============= */
.support_section {
  background-color: #000;
}
.support_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8rem;
  padding-bottom: 16rem;
}
.support_content span {
  font-size: 48px;
  line-height: 1.2;
  text-align: center;
  color: #fff;
}
.support_content p {
  font-size: 18px;
  text-align: center;
  margin-top: 1.5rem;
  color: #fff;
}
.support-btn {
  background-color: #6e45e9;
  border: 1px #000;
  border-radius: 200px;
  padding: 0.75rem 1.5rem;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  margin-top: 2rem;
  color: #fff;
  font-size: 16px;
}
/* ================= -FOOTER- ============= */
.footer {
  margin-top: 7rem;
  margin-bottom: 5rem;
  padding-left: 5%;
  padding-right: 5%;
}
.footer_content {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
}

/* left footer side */
.left_side {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
.left_side img {
  width: 135px;
  margin-bottom: 18px;
}
.left_side h6 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 3px;
}
.left_side span {
  font-size: 14px;
}
.left_side p {
  font-size: 14px;
  text-decoration: underline;
}
.socialMedia {
  display: flex;
  align-items: center;
  gap: 1.3rem;
  font-size: 20pxs;
  margin-top: 18px;
}
.socialIcon {
  font-size: 20px;
}

/* rigth side way in other way styling */
.footer_item_links {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
}
.links-footer {
  display: flex;
  flex-direction: column;
}
.links-footer a {
  font-size: 16px;
  padding-top: 8px;
  color: #000;
  margin-bottom: 0.7rem;
  transition: 0.5s ease-in;
  cursor: pointer;
}
.links-footer a:hover {
  color: #421abb;
}
.footer-btn {
  background-color: rgba(110, 69, 233, 0.11);
  border-radius: 8px;
  padding: 0.8rem;
  line-height: 1.5;
  font-weight: 400;
}
.footer-btn :hover {
  color: #6e45e9;
}

/* THE END */
.footer_end {
  margin-top: 6rem;
  display: flex;
  justify-content: space-between;
}
.footer_end p {
  font-size: 16px;
}
.footer_end span {
  text-decoration: underline;
  cursor: pointer;
}
.footer_end ul li {
  display: flex;
  gap: 2rem;
}

.footer_end ul li a {
  font-size: 14px;
  color: #000;
  text-decoration: underline;
}
/*  extra */
.extra {
  z-index: 999999;
}
.hire_us {
  position: relative;
  width: 100%;
  max-width: 310px;
  background-color: #000;
  border-radius: 8px;
  position: fixed;
  bottom: 5px;
  left: 5px;
}
.hire-us-wrapper {
  border-radius: 8px;
  padding: 15px;
  position: relative;
}
.hire-us-text {
  width: 90%;
  color: #fff;
  font-size: 0.8rem;
  margin-bottom: 12px;
}
.hire-us-btn {
  color: #fff;
  font-size: 0.8rem;
  border: 1px solid #eee;
  text-align: center;
  padding: 2px;
  text-decoration: underline;
  cursor: pointer;
}
.cross-icon {
  color: #fff;
  position: absolute;
  top: 5%;
  font-size: 1.2rem;
  right: 3%;
  cursor: pointer;
}
.product-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hide {
  display: flex;
  gap: 1rem;
  transition: all 0.3s ease-in;
}
.hide a:hover {
  color: #421abb;
}

.Log_in-btn {
  color: #000;
}
.sign_in_btn span {
  color: black;
}
.card_btn {
  transition: all 0.4s ease-in;
  cursor: pointer;
}
.card_btn:hover {
  background-color: #6e45e9;
  color: #fff;
}
.Basic_btn:hover,
.efficient_btn:hover,
.team_btn:hover {
  background-color: #6e45e9;
  color: #fff;
}
.customer_icon {
  background-color: #dcdcdc;
}

/**************** media querise **********************/
@media screen and (min-width: 1024px) {
  .home_img img {
    width: 566px;
    height: 460px;
  }
}
@media screen and (min-width: 1024px) {
  /* ========================>BOOK DEMO */
  .spendless_container {
    padding-left: 5%;
    padding-right: 5%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 5rem;
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
  .spendless_content h1 {
    background-image: linear-gradient(#000 44%, #757575 76%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 3.5rem;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  .spendless_desc {
    margin-bottom: 30px;
  }
  .spendless_details {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
  }
  .spendless_details h3 {
    font-size: 1.25rem;
  }
  .spendless_desc p {
    font-size: 1rem;
    line-height: 1.4;
    width: 79%;
  }
  .uniquefeature_container h3 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 43%, #686868 71%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 60px;
  }
  .uniquefeature_container {
    padding-right: 5%;
    padding-left: 5%;
  }
  .uniquefeature_content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
  }
  .uniquefeature_details {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  .uniquefeature_details h6 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 51%, #d6d6d6 84%, #c7c7c7);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    text-align: center;
    font-size: 1.325rem;
    margin-bottom: 20px;
    margin-top: 30px;
    line-height: 1.4;
  }
  .uniquefeature_content img {
    width: 235px;
    height: 224px;
    padding: 20px;
  }
  /* ========================>END +BOOK DEMO */

  /* ==========================>sign In / sign Out ACCOUNT */
  .signIn_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .login_logo img {
    height: 2.5rem;
    margin: 1.5rem 0.5%;
  }
  .login_logo {
    display: flex;
    padding-left: 10%;
    margin-bottom: 2rem;
  }
  .signIn_form_container {
    display: flex;
    margin: 0 4rem;
    flex-direction: column;
  }

  .signIn_form_container h1 {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 30px;
  }
  .signIn_form_container p {
    font-size: 1rem;
    font-family: Poppins, sans-serif;
    margin-bottom: 30px;
  }
  .SignIn_form-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .SignIn_form-content input {
    height: auto;
    min-height: 3.5rem;
    background-color: transparent;
    color: black;
    border: 1px solid #e0e0e0;
    border-radius: 200px;
    padding: 0.5rem 0.75rem 0.5rem 1rem;
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    line-height: 1.6;
    outline: none;
  }
  .lable_link {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .signIn_link {
    text-decoration: underline;
    color: #000;
    cursor: pointer;
  }
  .form_container {
    display: grid;
    gap: 30px;
  }
  .signIN_btns {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .Log_in-btn,
  .sign_in_btn {
    padding: 15px 30px;
  }
  .Log_in-btn {
    background-color: #6e45e9;
    color: #fff;
    font-weight: 500;
  }
  .sign_in_btn {
    border: 1px solid #000;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .sign_in_btn span {
    font-size: 14px;
    font-weight: bold;
  }
  .google_icon {
    font-size: 1.2rem;
  }
  .signIn_img {
    padding: 20px;
  }
  .signIn_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }
  .login-link {
    display: flex;
    justify-content: center;
    gap: 4px;
    margin-top: 20px;
  }
  .login-link a {
    text-decoration: underline;
    color: #000;
    cursor: pointer;
  }

  /* ****************************>end sign In / sign Out END ACCOUNT */
  .header {
    padding: 23px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6rem;
    margin-top: 2px;
  }
  .header-content img {
    width: 142px;
  }

  /* feature editing part */
  .product_contantainer {
    padding: 25px;
  }
  .product_title {
    font-size: 40px;
    text-align: center;
    margin-bottom: 20px;
  }
  .product_subtitle {
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
  }
  .product-btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product-btn {
    background-color: #6e45e9;
    color: #fff;
    font-size: 16px;
  }
  .product-img img {
    margin-top: 50px;
    width: 974px;
    height: 586px;
  }

  /* END feature editing part */
  .home {
    margin-top: 93px;
    padding: 0 50px;
  }
  .home_img img {
    width: 450px;
    height: 364px;
  }
  /* +================>PRICING DETAILS */
  .pd_container {
    padding: 20px 20px;
  }
  .pd_heading {
    text-align: center;
  }
  .pd-title {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 44%, #757575 76%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2.25rem;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  .pd_heading p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 40px;
  }
  .pd_card-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 50px;
  }
  .month_btn,
  .Year-btn {
    border-radius: 7px;
    padding: 0.5rem 1.5rem;
    background-color: transparent;
    font-size: 15px;
  }
  .active_btn {
    background-color: #000;
    color: #fff;
  }
  .card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    background-color: #fff;
    background-image: linear-gradient(330deg, #ececec, #fff 69%);
    border: 1px solid #e1e1e1;
    border-radius: 20px;
    padding: 41px 32px 69px;
    margin-bottom: 15px;
  }
  .card_heading {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .card_img {
    width: 73px;
  }
  .card_titile {
    font-size: 1rem;
    color: #000;
    font-weight: 600;
    font-family: Poppins, sans-serif;
  }
  .card_description-1 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2;
    margin-top: 20px;
  }
  .card_btn {
    border: 1px solid #000;
    font-size: 15px;
    border-radius: 35px;
    padding: 0.75rem 1.5rem;
    background-color: #fff;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    transition: background-color 0.25s;
  }
  .card_description-2 {
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    margin-bottom: 13px;
  }
  .list {
    line-height: 1.5;
  }
  .listItem {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 14px;
  }
  .list_icon {
    font-size: 25px;
  }
  .CPS_container {
    padding: 20px 30px;
  }
  .CPS_heading {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .CPS_heading h2 {
    font-size: 2.25rem;
    text-align: center;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 43%, #686868 71%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    line-height: 1.2;
    margin-bottom: 15px;
  }
  .CPS_eachPlan {
    display: flex;
    align-items: center;
    justify-content: right;
    flex-direction: row;
    margin-bottom: 40px;
    gap: 20px;
  }
  .basic,
  .efficient,
  .team {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .basic_heading,
  .efficient_heading,
  .team_heading {
    font-size: 1.325rem;
    margin-bottom: 16px;
    margin-top: 30px;
  }
  .basic_amount,
  .efficient_amount,
  .team_amount {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 16px;
  }
  .efficient_amount span,
  .team_amount span {
    font-weight: normal;
  }
  .basic_discription,
  .efficient_discription,
  .team_discription {
    display: none;
  }
  .Basic_btn,
  .efficient_btn,
  .team_btn {
    width: 150px;
    padding: 15px 30px;
    font-size: 1rem;
    background-color: transparent;
    border: 1px solid black;
    cursor: pointer;
    transition: all 0.3s ease-in;
  }

  /* ===>coreFeature */
  .CF_heading {
    margin-bottom: 60px;
  }
  .CF_content {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr;
    margin-bottom: 9px;
    padding: 0 35px;
    border-bottom: 1px solid #e4e4e4;
  }
  .pricing_feature {
    padding: 0.5rem 0;
    margin-top: 5px;
  }
  .pricing_row {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    line-height: 1.5;

    font-weight: bold;
  }

  /* END COMPARE PLANS */
  /* FQA */
  .FQA_container {
    padding-left: 5%;
    padding-right: 5%;
  }
  .FQA_heading {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 40%, #6b6b6b 72%, #dfdfdf);
    -webkit-background-clip: text;
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 50px;
  }
  .FQA_heading span {
    font-weight: bold;
  }
  .FQA_content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .FQA_question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    cursor: pointer;
    font-size: 1.125rem;
    color: #000;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    padding: 20px 0;
    border-top: 1px solid black;
  }
  .hideContent {
    overflow: hidden;
    transform: translateY(-30px);
    max-height: 0;
    transition: all 0.5s ease-in-out;
  }
  .showContent {
    max-height: 999px;
    transition: all 0.5s ease-in;
    padding-bottom: 30px;
  }
  .card_content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 27px;
  }
  /* END FQA */
  /* ***************END PRICING DETAILS */

  /* ==========================>AABOUT US STYLING */
  /* BETTER PRODUCT */
  .BetterProduct_container {
    background-color: #000;
    background-image: url(https://assets-global.website-files.com/6465ef4…/646614b…_Circles%20Shapes%20Background.png);
  }
  .BetterPofuct_content {
    color: #fff;
  }
  .BetterPofuct_heading {
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .BetterPofuct_heading h1 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#fff, #c7c7c7 67%, #797979);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2.5rem;
    line-height: 1.2;
    margin-bottom: 2rem;
  }
  .BetterPofuct_heading p {
    margin-bottom: 2rem;
  }
  .company_features {
    padding: 20px 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 20px;
    column-gap: 30px;
  }
  .company_feature {
    background-color: rgba(124, 124, 124, 0.29);
    border: 1px solid #000;
    border-radius: 16px;
    padding: 28px 21px;
    color: #fff;
  }
  .company_feature-content h2 {
    font-size: 1.5rem;
    line-height: 1.4;
    font-weight: 600;
    margin-bottom: 10px;
  }
  /* END BETTER PRODUCT */

  /* WORK SMATTER */
  .WorkSmatter_container {
    padding-left: 5%;
    padding-right: 5%;
  }
  .WorkSmatter_heading {
    text-align: center;
  }
  .WorkSmatter_heading h3 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 44%, #757575 76%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 3.5rem;
  }
  .WorkSmatter_heading p {
    font-size: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .workSmatter_info {
    display: flex;
    flex-direction: row;
    gap: 36px;
    margin-bottom: 30px;
  }
  .workSmatter_info-facts {
    padding: 26px 34px;
    background-color: #f3f3f3;
    border-radius: 20px;
  }
  .facts_title {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 17px;
    font-size: 20px;
  }
  /* ND WORK SMATTER */

  /* TEAM MEMBER*/
  .team_members {
    padding-left: 5%;
    padding-right: 5%;
    background-image: linear-gradient(#fff, #ececec);
  }
  .team_members-heading {
    margin-bottom: 60px;
    margin-top: 60px;
  }
  .team_members-heading h1 {
    font-size: 2.5rem;
    text-align: center;
    line-height: 1.2;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 43%, #686868 71%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    margin-bottom: 20px;
  }
  .team_members-heading p {
    font-size: 1.125rem;
    text-align: center;
    line-height: 1.6;
    padding: 0 7rem;
  }
  .team_members-card {
    background-color: #fff;
    padding: 25px;
    border-radius: 14px;
  }
  .team_members-cards-details {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
    padding-bottom: 5rem;
  }
  .team_members-content img {
    width: 100%;
  }
  .team_members-content h5 {
    font-size: 18px;
    margin-top: 10px;
  }
  .team_members-content p {
    font-size: 18px;
    padding-bottom: 20px;
    border-bottom: 0.5px solid #aaa;
  }
  .social_Media {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }
  .social_Media p {
    font-size: 20px;
  }
  /* END TEAM MEMBER */

  /* >CURRENTLY */
  .currently_container {
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 60px;
  }
  .currently_heading h1 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 51%, #d6d6d6 84%, #c7c7c7);
    text-align: center;
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    margin-bottom: 1rem;
  }
  .currently_heading p {
    font-size: 1rem;
    text-align: center;
    line-height: 1.6;
  }
  .currently_content {
    width: 100%;
    margin-top: 4rem;
  }
  .currenlty_wrappper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .currrent_details {
    padding: 1.5rem;
    background-color: #f5f5f5;
    border-radius: 14px;
  }

  .currently_content h3 {
    font-weight: 600;
    line-height: 1.4;
    font-size: 1.7rem;
    margin-bottom: 16px;
  }
  .location {
    display: flex;
    flex-direction: row;
    gap: 40px;
  }
  .location_box,
  .time_box {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .location_box p,
  .time_box p {
    margin-top: 3.9px;
  }
  .current_desc {
    font-size: 1.3rem;
  }
  .currently_icons {
    font-size: 23px;
  }
  .carrenltly_roles {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 5rem;
    gap: 10px;
    margin-bottom: 6rem;
  }
  .carrenltly_roles p {
    width: 100%;
    max-width: 35rem;
  }
  .carrenltly_roles h6 {
    font-size: 1.125rem;
  }
  /* > END CURRENTLY */
  /* =============================>  END ABOUT US STYLING
  
  /* ======================>CONTACT */
  .contactInfo_container {
    padding-left: 5%;
    padding-right: 5%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5rem;
    margin: 5rem 0;
  }
  .contactInfo_content h1 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 44%, #757575 76%, #dfdfdf);
    -webkit-background-clip: text;
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 3.5rem;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  .contactInfo_content p {
    margin-bottom: 15px;
  }
  .contant_info-details-desc {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .contactInfo_content-Card h3 {
    margin-top: 10px;
  }
  .contant_info-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .form {
    background-color: #f3f3f3;
    padding: 28px;
    border-radius: 14px;
  }
  .info {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  .info label {
    margin-bottom: 0.25rem;
    font-weight: 500;
    font-size: 16px;
  }
  .info input {
    padding: 14px 15px;
    border-radius: 6px;
    width: 100%;
    color: #333;
    font-size: 14px;
    outline: none;
    background-color: #fff;
    line-height: 1.42857;
  }
  .info input::placeholder {
    font-size: 14px;
    font-family: Poppins, sans-serif;
  }
  .info textarea {
    border-radius: 10px;
    width: 100%;
    padding: 8px 26px;
  }
  .info textarea::placeholder {
    font-size: 14px;
  }
  .text_area {
    font-size: 14px;
    height: 38%;
  }
  .text_area::placeholder {
    font-size: 10px;
    font-family: Poppins, sans-serif;
  }
  .form_btn {
    background-color: #6e45e9;
    color: #fff;
    text-align: center;
    border-radius: 200px;
    padding: 0.75rem 1.5rem;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 16px;
  }
  .form_field {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-bottom: 1.2rem;
  }
  /* ======================>END +CONTACT */

  /* +=======================>BLOG   */
  .card_box {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 30px;
    margin-bottom: 3rem;
  }
  .cardImg {
    width: 100%;
    height: 160px;
    object-fit: cover;
  }
  .box_content h1 {
    max-height: 3.4rem;
    overflow: hidden;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.4;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  .blog_buttons {
    overflow: scroll;
    padding: 0.5rem 3rem;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .blog_btn {
    background-color: transparent;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 600;
  }
  .blog_btn_active {
    border: 1px solid #505050;
  }

  /* ******** */
  .analytics_container {
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 50px;
  }
  .analytics_heading {
    font-size: 3.3rem;
    font-weight: 300;
    margin-bottom: 20px;
  }
  .analytic_card {
    border: 1px solid #eae9e9;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .analytic_card-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .analytic_card-content {
    display: flex;
    flex-direction: column;
  }
  .analytic_body {
    padding: 20px 40px;
  }
  .analytic_btn {
    border-radius: 10px;
    padding: 6px 8px;
    font-size: 16px;
    margin: 13px 0;
  }
  .analytic_card-content-title {
    font-size: 1.8rem;
    line-height: 1.4;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .analytic_card-content-details {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 2.5rem;
  }
  .analytic_card-content-details img {
    width: 45px;
    height: 45px;
  }
  .analytic_card-content-details p {
    font-size: 14px;
  }
  .login_accounts {
    display: none;
  }
  /* +=======================> END-->BLOG   */
}

@media screen and (max-width: 992px) {
  .login_accounts {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .bookdemo_btn a {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    color: #fff;
    padding: 9px 0;
    border-radius: 30px;
    font-size: 15px;
  }
  .bookmode_icon {
    margin-top: 7px;
    margin-left: 10px;
  }

  /* ========================>BOOK DEMO */
  .demo_logos {
    margin-bottom: 4rem;
  }
  .spendless_container {
    padding-left: 5%;
    padding-right: 5%;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 5rem;
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
  .spendless_content h1 {
    background-image: linear-gradient(#000 44%, #757575 76%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 3.5rem;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  .spendless_desc {
    margin-bottom: 30px;
  }
  .spendless_details {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
  }
  .spendless_details h3 {
    font-size: 1.25rem;
  }
  .spendless_desc p {
    font-size: 1rem;
    line-height: 1.4;
    width: 49%;
  }
  .uniquefeature_container h3 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 43%, #686868 71%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 60px;
  }
  .uniquefeature_container {
    padding-right: 5%;
    padding-left: 5%;
  }
  .uniquefeature_content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    margin-bottom: 5rem;
  }
  .uniquefeature_details {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  .uniquefeature_details h6 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 51%, #d6d6d6 84%, #c7c7c7);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    text-align: center;
    font-size: 1.325rem;
    margin-bottom: 20px;
    margin-top: 30px;
    line-height: 1.4;
  }
  .uniquefeature_content img {
    width: 235px;
    height: 224px;
    padding: 20px;
  }
  /* ========================>END +BOOK DEMO */
  /* ==========================>sign In / sign Out ACCOUNT */
  .signIn_container {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr;
  }
  .login_logo img {
    height: 2.5rem;
    margin: 1.5rem 0.5%;
  }
  .login_logo {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }
  .signIn_form_container {
    width: 100%;
    max-width: 30rem;
    margin-right: auto;
    margin-left: auto;
  }

  .signIn_form_container h1 {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 30px;
  }
  .signIn_form_container p {
    font-size: 1rem;
    font-family: Poppins, sans-serif;
    margin-bottom: 30px;
  }
  .SignIn_form-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .SignIn_form-content input {
    height: auto;
    min-height: 3.5rem;
    background-color: transparent;
    color: black;
    border: 1px solid #e0e0e0;
    border-radius: 200px;
    padding: 0.5rem 0.75rem 0.5rem 1rem;
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    line-height: 1.6;
    outline: none;
  }
  .lable_link {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .signIn_link {
    text-decoration: underline;
    color: #000;
    cursor: pointer;
  }
  .form_container {
    display: grid;
    gap: 30px;
  }
  .signIN_btns {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .Log_in-btn,
  .sign_in_btn {
    padding: 15px 30px;
  }
  .Log_in-btn {
    background-color: #6e45e9;
    color: #fff;
    font-weight: 500;
  }
  .sign_in_btn {
    border: 1px solid #000;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .sign_in_btn span {
    font-size: 14px;
    font-weight: bold;
  }
  .google_icon {
    font-size: 1.2rem;
  }
  /* .signIn_img {
    padding: 20px;
  } */
  .signIn_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }
  .login-link {
    display: flex;
    justify-content: center;
    gap: 4px;
    margin-top: 20px;
  }
  .login-link a {
    text-decoration: underline;
    color: #000;
    cursor: pointer;
  }
  /* ****************************>end sign In / sign Out END ACCOUNT */
  /* +================>PRICING DETAILS */
  .link_box {
    display: none;
  }
  .pd_container {
    padding: 20px 20px;
  }
  .pd_heading {
    text-align: center;
  }
  .pd-title {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 44%, #757575 76%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2.25rem;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  .pd_heading p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 40px;
  }
  .pd_card-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 50px;
  }
  .month_btn,
  .Year-btn {
    border-radius: 7px;
    padding: 0.5rem 1.5rem;
    background-color: transparent;
    font-size: 15px;
  }
  .active_btn {
    background-color: #000;
    color: #fff;
  }
  .card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    background-color: #fff;
    background-image: linear-gradient(330deg, #ececec, #fff 69%);
    border: 1px solid #e1e1e1;
    border-radius: 20px;
    padding: 41px 32px 69px;
    margin-bottom: 15px;
  }
  .card_heading {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .card_img {
    width: 73px;
  }
  .card_titile {
    font-size: 1rem;
    color: #000;
    font-weight: 600;
    font-family: Poppins, sans-serif;
  }
  .card_description-1 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2;
    margin-top: 20px;
  }
  .card_btn {
    border: 1px solid #000;
    font-size: 15px;
    border-radius: 35px;
    background-color: #fff;
    padding: 0.75rem 1.5rem;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    transition: background-color 0.25s;
  }
  .card_description-2 {
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    margin-bottom: 13px;
  }
  .list {
    line-height: 1.5;
  }
  .listItem {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 14px;
  }
  .list_icon {
    font-size: 25px;
  }
  .CPS_container {
    padding: 20px 30px;
  }
  .CPS_heading {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .CPS_heading h2 {
    font-size: 2.25rem;
    text-align: center;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 43%, #686868 71%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    line-height: 1.2;
    margin-bottom: 15px;
  }
  .CPS_eachPlan {
    display: flex;
    align-items: center;
    justify-content: right;
    flex-direction: row;
    margin-bottom: 40px;
    gap: 20px;
  }
  .basic,
  .efficient,
  .team {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .basic_heading,
  .efficient_heading,
  .team_heading {
    font-size: 1.325rem;
    margin-bottom: 16px;
    margin-top: 30px;
  }
  .basic_amount,
  .efficient_amount,
  .team_amount {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 16px;
  }
  .efficient_amount span,
  .team_amount span {
    font-weight: normal;
  }
  .basic_discription,
  .efficient_discription,
  .team_discription {
    display: none;
  }
  .Basic_btn,
  .efficient_btn,
  .team_btn {
    width: 150px;
    padding: 15px 30px;
    font-size: 1rem;
    background-color: transparent;
    border: 1px solid black;
  }
  .efficient_btn {
    background-color: #6e45e9;
    color: #fff;
  }
  /* ===>coreFeature */
  .CF_heading {
    margin-bottom: 60px;
  }
  .CF_content {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr;
    margin-bottom: 9px;
    padding: 0 35px;
    border-bottom: 1px solid #e4e4e4;
  }
  .pricing_feature {
    padding: 0.5rem 0;
    margin-top: 5px;
  }
  .pricing_row {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    line-height: 1.5;

    font-weight: bold;
  }

  /* END COMPARE PLANS */
  /* FQA */
  .FQA_container {
    padding-left: 5%;
    padding-right: 5%;
  }
  .FQA_heading {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 40%, #6b6b6b 72%, #dfdfdf);
    -webkit-background-clip: text;
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 50px;
  }
  .FQA_heading span {
    font-weight: bold;
  }
  .FQA_content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .FQA_question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    cursor: pointer;
    font-size: 1.125rem;
    color: #000;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    padding: 20px 0;
    border-top: 1px solid black;
  }

  .hideContent {
    overflow: hidden;
    transform: translateY(-30px);
    max-height: 0;
    transition: all 0.5s ease-in-out;
  }
  .showContent {
    max-height: 999px;
    transition: all 0.5s ease-in;
    padding-bottom: 30px;
  }
  /* END FQA */
  /* ***************************END PRICING DETAILS */

  /* ==========================>AABOUT US STYLING */
  /* BETTER PRODUCT */
  .BetterProduct_container {
    background-color: #000;
    background-image: url(https://assets-global.website-files.com/6465ef4…/646614b…_Circles%20Shapes%20Background.png);
  }
  .BetterPofuct_content {
    color: #fff;
  }
  .BetterPofuct_heading {
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .BetterPofuct_heading h1 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#fff, #c7c7c7 67%, #797979);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2.5rem;
    line-height: 1.2;
    margin-bottom: 2rem;
  }
  .BetterPofuct_heading p {
    margin-bottom: 2rem;
  }
  .company_features {
    padding: 20px 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
    column-gap: 30px;
  }
  .company_feature {
    background-color: rgba(124, 124, 124, 0.29);
    border: 1px solid #000;
    border-radius: 16px;
    padding: 28px 21px;
    color: #fff;
  }
  .company_feature-content h2 {
    font-size: 1.5rem;
    line-height: 1.4;
    font-weight: 600;
    margin-bottom: 10px;
  }
  /* END BETTER PRODUCT */

  /* WORK SMATTER */
  .WorkSmatter_container {
    padding: 20px 32px;
  }
  .WorkSmatter_heading {
    text-align: center;
  }
  .WorkSmatter_heading h3 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 44%, #757575 76%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2.5rem;
  }
  .WorkSmatter_heading p {
    font-size: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .workSmatter_info {
    display: flex;
    flex-direction: row;
    gap: 36px;
    margin-bottom: 30px;
  }
  .workSmatter_info-facts {
    padding: 26px 34px;
    background-color: #f3f3f3;
    border-radius: 20px;
  }
  .facts_title {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 17px;
    font-size: 20px;
  }
  /* ND WORK SMATTER */

  /* TEAM MEMBER*/
  .team_members {
    padding: 20px 20px;
    background-image: linear-gradient(#fff, #ececec);
  }
  .team_members-heading {
    margin-bottom: 60px;
  }
  .team_members-heading h1 {
    font-size: 2.5rem;
    text-align: center;
    line-height: 1.2;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 43%, #686868 71%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    margin-bottom: 20px;
  }
  .team_members-heading p {
    font-size: 1rem;
    text-align: center;
    line-height: 1.6;
  }
  .team_members-card {
    background-color: #fff;
    padding: 25px;
    border-radius: 14px;
  }
  .team_members-cards-details {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
  .team_members-content img {
    width: 100%;
  }
  .team_members-content h5 {
    font-size: 18px;
    margin-top: 10px;
  }
  .team_members-content p {
    font-size: 18px;
    padding-bottom: 20px;
    border-bottom: 0.5px solid #aaa;
  }
  .social_Media {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }
  .social_Media p {
    font-size: 20px;
  }
  /* END TEAM MEMBER */

  /* >CURRENTLY */
  .currently_container {
    padding: 20px 20px;
    margin-top: 60px;
  }
  .currently_heading h1 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 51%, #d6d6d6 84%, #c7c7c7);
    text-align: center;
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    margin-bottom: 1rem;
  }
  .currently_heading p {
    font-size: 1rem;
    text-align: center;
    line-height: 1.6;
  }
  .currently_content {
    width: 100%;
    margin-top: 4rem;
  }
  .currenlty_wrappper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 26px;
  }
  .currrent_details {
    padding: 1.5rem;
    background-color: #f5f5f5;
    border-radius: 14px;
  }

  .currently_content h3 {
    font-weight: 600;
    line-height: 1.4;
    font-size: 1.7rem;
    margin-bottom: 16px;
  }
  .location {
    display: flex;
    flex-direction: row;
    gap: 40px;
  }
  .location_box,
  .time_box {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .location_box p,
  .time_box p {
    margin-top: 3.9px;
  }
  .current_desc {
    font-size: 1.3rem;
  }
  .currently_icons {
    font-size: 23px;
  }
  .carrenltly_roles {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 5rem;
    gap: 10px;
  }
  .carrenltly_roles p {
    width: 100%;
    max-width: 35rem;
  }
  .carrenltly_roles h6 {
    font-size: 1.125rem;
  }
  /* > END CURRENTLY */
  /* =============================>END ABOUT US STYLING */

  /* ======================>CONTACT */
  .contactInfo_container {
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 3rem;
  }
  .contactInfo_content h1 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 44%, #757575 76%, #dfdfdf);
    -webkit-background-clip: text;
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2.5rem;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  .contactInfo_content p {
    margin-bottom: 15px;
  }
  .contant_info-details-desc {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .contactInfo_content-Card h3 {
    margin-top: 10px;
  }
  .contant_info-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .form {
    background-color: #f3f3f3;
    padding: 12px;
    border-radius: 14px;
  }
  .info {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  .info label {
    margin-bottom: 0.25rem;
    font-weight: 500;
    font-size: 16px;
  }
  .info input {
    padding: 14px 15px;
    border-radius: 6px;
    width: 100%;
    color: #333;
    font-size: 14px;
    outline: none;
    background-color: #fff;
    line-height: 1.42857;
  }
  .info input::placeholder {
    font-size: 14px;
    font-family: Poppins, sans-serif;
  }
  .info textarea {
    border-radius: 10px;
  }
  .info textarea::placeholder {
    font-size: 14px;
    padding: 0 16px;
  }
  .text_area {
    padding: 8px;
    font-size: 12px;
  }
  .text_area::placeholder {
    font-size: 10px;
    font-family: Poppins, sans-serif;
    color: #333;
  }
  .form_btn {
    background-color: #6e45e9;
    color: #fff;
    text-align: center;
    border-radius: 200px;
    padding: 0.75rem 1.5rem;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .form_field {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  /* ======================>END +CONTACT */

  /* +=======================>BLOG   */
  .card_box {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 30px;
  }
  .cardImg {
    width: 100%;
    height: 235px;
    object-fit: cover;
  }
  .box_content h1 {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.4;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  .blog_buttons {
    overflow: scroll;
    padding: 0.5rem 3rem;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .blog_btn {
    background-color: transparent;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 600;
  }
  .blog_btn_active {
    border: 1px solid #505050;
  }

  /* ******** */
  .analytics_container {
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 50px;
  }
  .analytics_heading {
    font-size: 3.3rem;
    font-weight: 300;
    margin-bottom: 20px;
  }
  .analytic_card {
    border: 1px solid #eae9e9;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .analytic_card-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .analytic_card-content {
    display: flex;
    flex-direction: column;
  }
  .analytic_body {
    padding: 20px 40px;
  }
  .analytic_btn {
    border-radius: 10px;
    padding: 6px 8px;
    font-size: 16px;
    margin: 13px 0;
  }
  .analytic_card-content-title {
    font-size: 1.8rem;
    line-height: 1.4;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .analytic_card-content-details {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 2.5rem;
  }
  .analytic_card-content-details img {
    width: 45px;
    height: 45px;
  }
  .analytic_card-content-details p {
    font-size: 14px;
  }
  /* +=======================>END BLOG   */

  /* feature editing part */
  .product_contantainer {
    padding: 25px;
  }
  .product_title {
    font-size: 40px;
    text-align: center;
    margin-bottom: 20px;
  }
  .product_subtitle {
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
  }
  .product-btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product-btn {
    background-color: #6e45e9;
    color: #fff;
    font-size: 16px;
  }
  .product-img img {
    margin-top: 50px;
    width: 938px;
    height: 586px;
  }

  /* END feature editing part */
  .home {
    margin-top: 93px;
    padding: 0 50px;
  }
  .header {
    padding: 10px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .section {
    margin: 5rem 0;
    padding: 0 46px;
  }

  .real_time {
    display: none;
  }
  #btn {
    display: none;
  }
  .nav_button {
    width: 100%;
    background-color: black;
  }
  .customer_icon {
    display: none;
  }
  /* ---------------------------------home media queries */
  .home {
    grid-template-columns: 1fr;
    align-items: center;
    margin-top: 29px;
  }
  .home_img img {
    display: none;
  }
  .home_content h2 {
    font-size: 3.2rem;
  }
  /* ----------------------------------------demooooo */
  .demo_section {
    padding: 0 46px;
    grid-template-columns: 1fr;
  }
  .demo_content {
    grid-row: 1;
  }
  .demo_section {
    gap: 4rem;
    margin: 5rem 0;
  }
  .demo_component video {
    width: 856px;
    height: auto;
  }
  /*  ------------------------------------intergrated */
  .integer-content {
    column-gap: 27px;
  }
  .integrate-heading {
    padding-top: 46px;
  }

  /*------------------------------------------- minimize */
  .minimize_content {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .min-image {
    grid-row: 1;
  }
  /* ---------------------------------------client */
  .client_content {
    grid-template-columns: 1fr;
    gap: 2rem;
    padding-top: 1px;
  }
  .client_img-box {
    grid-row: 1;
  }
  /* bussness */
  .business_content {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
  .business-img-box {
    justify-content: flex-start;
  }
  .client_img-box {
    justify-content: flex-start;
  }
  /* card */
  .card_box {
    grid-template-columns: 1fr 1fr;
  }
  /*-------------------------- foooter */
  .footer_content {
    display: grid;
    grid-template-columns: 1fr;
  }
  .left_side {
    margin-bottom: 4rem;
    gap: 0rem;
  }
  .footer-btn {
    width: fit-content;
  }
  .adressess {
    display: none;
  }
  .nav_menu {
    display: block;
    color: #fff;
  }
  /* hide navigation */
  .hide {
    position: absolute;
    top: 16%;
    left: 0;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 20px;
    transform: translateY(-170vh);
    transition: 1s;
  }

  /* menu */
  .show {
    position: absolute;
    top: 16%;
    left: 0;
    font-size: 20px;
    padding: 28px;
    padding-top: 16px;
    height: 100vh;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 100000;
    transform: translateY(1vh);
    transition: 1s;
  }
}

@media screen and (max-width: 767px) {
  /* ========================>BOOK DEMO */
  .demo_logos {
    margin-bottom: 4rem;
  }
  .spendless_container {
    padding-left: 5%;
    padding-right: 5%;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 5rem;
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
  .spendless_content h1 {
    background-image: linear-gradient(#000 44%, #757575 76%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2.5rem;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  .spendless_desc {
    margin-bottom: 30px;
  }
  .spendless_details {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
  }
  .spendless_details h3 {
    font-size: 1.25rem;
  }
  .spendless_desc p {
    font-size: 1rem;
    line-height: 1.4;
    width: 99%;
  }
  .uniquefeature_container h3 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 43%, #686868 71%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 60px;
  }
  .uniquefeature_container {
    padding-right: 5%;
    padding-left: 5%;
  }
  .uniquefeature_content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-bottom: 5rem;
  }
  .uniquefeature_details {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  .uniquefeature_details h6 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 51%, #d6d6d6 84%, #c7c7c7);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    text-align: center;
    font-size: 1.125rem;
    margin-bottom: 20px;
    margin-top: 30px;
    line-height: 1.4;
  }
  .uniquefeature_content img {
    width: 581px;
    height: 552px;
    padding: 20px;
  }
  /* ========================>END +BOOK DEMO */
  /* ==========================>sign In / sign Out ACCOUNT */
  .signIn_container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
  }
  .login_logo img {
    height: 2.5rem;
    margin: 1.5rem 0.5%;
  }
  .login_logo {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }
  .signIn_form_container {
    width: 100%;
    max-width: 30rem;
    margin-right: auto;
    margin-left: auto;
  }

  .signIn_form_container h1 {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 30px;
  }
  .signIn_form_container p {
    font-size: 1rem;
    font-family: Poppins, sans-serif;
    margin-bottom: 30px;
  }
  .SignIn_form-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .SignIn_form-content input {
    height: auto;
    min-height: 3.5rem;
    background-color: transparent;
    color: black;
    border: 1px solid #e0e0e0;
    border-radius: 200px;
    padding: 0.5rem 0.75rem 0.5rem 1rem;
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    line-height: 1.6;
    outline: none;
  }
  .lable_link {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .signIn_link {
    text-decoration: underline;
    color: #000;
    cursor: pointer;
  }
  .form_container {
    display: grid;
    gap: 30px;
  }
  .signIN_btns {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .Log_in-btn,
  .sign_in_btn {
    padding: 15px 30px;
  }
  .Log_in-btn {
    background-color: #6e45e9;
    color: #fff;
    font-weight: 500;
  }
  .sign_in_btn {
    border: 1px solid #000;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .sign_in_btn span {
    font-size: 14px;
    font-weight: bold;
  }
  .google_icon {
    font-size: 1.2rem;
  }

  .signIn_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }
  .login-link {
    display: flex;
    justify-content: center;
    gap: 4px;
    margin-top: 20px;
  }
  .login-link a {
    text-decoration: underline;
    color: #000;
    cursor: pointer;
  }
  /* ****************************>end sign In / sign Out 

  /* +================>PRICING DETAILS */
  .link_box {
    display: none;
  }

  .pd_container {
    padding: 20px 20px;
  }
  .pd_heading {
    text-align: center;
  }
  .pd-title {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 44%, #757575 76%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2.25rem;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  .pd_heading p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 40px;
  }
  .pd_card-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 50px;
  }
  .month_btn,
  .Year-btn {
    border-radius: 7px;
    padding: 0.5rem 1.5rem;
    background-color: transparent;
    font-size: 15px;
  }
  .active_btn {
    background-color: #000;
    color: #fff;
  }
  .card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    background-color: #fff;
    background-image: linear-gradient(330deg, #ececec, #fff 69%);
    border: 1px solid #e1e1e1;
    border-radius: 20px;
    padding: 41px 32px 69px;
    margin-bottom: 15px;
  }
  .card_heading {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .card_img {
    width: 73px;
  }
  .card_titile {
    font-size: 1rem;
    color: #000;
    font-weight: 600;
    font-family: Poppins, sans-serif;
  }
  .card_description-1 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2;
    margin-top: 20px;
  }
  .card_btn {
    border: 1px solid #000;
    font-size: 15px;
    border-radius: 35px;
    background-color: #fff;
    padding: 0.75rem 1.5rem;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    transition: background-color 0.25s;
  }
  .card_description-2 {
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    margin-bottom: 13px;
  }
  .list {
    line-height: 1.5;
  }
  .listItem {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 14px;
  }
  .list_icon {
    font-size: 25px;
  }
  .CPS_container {
    padding: 20px 30px;
  }
  .CPS_heading {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .CPS_heading h2 {
    font-size: 2.25rem;
    text-align: center;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 43%, #686868 71%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    line-height: 1.2;
    margin-bottom: 15px;
  }
  .CPS_eachPlan {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 40px;
    gap: 20px;
  }
  .basic,
  .efficient,
  .team {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .basic_heading,
  .efficient_heading,
  .team_heading {
    font-size: 1.325rem;
    margin-bottom: 16px;
    margin-top: 30px;
  }
  .basic_amount,
  .efficient_amount,
  .team_amount {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 16px;
  }
  .efficient_amount span,
  .team_amount span {
    font-weight: normal;
  }
  .basic_discription,
  .efficient_discription,
  .team_discription {
    display: none;
  }
  .Basic_btn,
  .efficient_btn,
  .team_btn {
    width: 202px;
    padding: 13px 30px;
    font-size: 1.1rem;
    background-color: transparent;
    border: 1px solid black;
  }
  .efficient_btn {
    background-color: #6e45e9;
    color: #fff;
  }
  /* ===>coreFeature */
  .CF_heading {
    margin-bottom: 60px;
  }
  .CF_content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 9px;
    padding: 0 25px;
  }
  .pricing_feature {
    padding: 0.5rem 0;
    margin-top: 5px;
    grid-area: span 1 / span 3 / span 1 / span 3;
  }
  .pricing_row {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
    line-height: 1.5;
    border-bottom: 1px solid #e4e4e4;
    font-weight: bold;
  }

  /* END COMPARE PLANS */
  /* FQA */
  .FQA_container {
    padding: 20px 30px;
  }
  .FQA_heading {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 40%, #6b6b6b 72%, #dfdfdf);
    -webkit-background-clip: text;
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 50px;
  }
  .FQA_heading span {
    font-weight: bold;
  }
  .FQA_content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .FQA_question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    cursor: pointer;
    font-size: 1.125rem;
    color: #000;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    padding: 20px 0;
    border-top: 1px solid black;
  }

  .hideContent {
    overflow: hidden;
    transform: translateY(-30px);
    max-height: 0;
    transition: all 0.5s ease-in-out;
  }
  .showContent {
    max-height: 999px;
    transition: all 0.5s ease-in;
    padding-bottom: 30px;
  }
  /* END FQA */
  /* ***************************END PRICING DETAILS */

  /* ==========================>AABOUT US STYLING */
  /* BETTER PRODUCT */
  .BetterProduct_container {
    background-color: #000;
    background-image: url(https://assets-global.website-files.com/6465ef4…/646614b…_Circles%20Shapes%20Background.png);
  }
  .BetterPofuct_content {
    color: #fff;
  }
  .BetterPofuct_heading {
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .BetterPofuct_heading h1 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#fff, #c7c7c7 67%, #797979);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2.5rem;
    line-height: 1.2;
    margin-bottom: 2rem;
  }
  .BetterPofuct_heading p {
    margin-bottom: 2rem;
  }
  .company_features {
    padding: 20px 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
    column-gap: 30px;
  }
  .company_feature {
    background-color: rgba(124, 124, 124, 0.29);
    border: 1px solid #000;
    border-radius: 16px;
    padding: 28px 21px;
    color: #fff;
  }
  .company_feature-content h2 {
    font-size: 1.5rem;
    line-height: 1.4;
    font-weight: 600;
    margin-bottom: 10px;
  }
  /* END BETTER PRODUCT */

  /* WORK SMATTER */
  .WorkSmatter_container {
    padding: 20px 32px;
  }
  .WorkSmatter_heading {
    text-align: center;
  }
  .WorkSmatter_heading h3 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 44%, #757575 76%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2.5rem;
  }
  .WorkSmatter_heading p {
    font-size: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .workSmatter_info {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .workSmatter_info-facts {
    padding: 26px 34px;
    background-color: #f3f3f3;
    border-radius: 20px;
  }
  .facts_title {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 17px;
  }
  /* ND WORK SMATTER */

  /* TEAM MEMBER*/
  .team_members {
    padding: 20px 20px;
    background-image: linear-gradient(#fff, #ececec);
  }
  .team_members-heading {
    margin-bottom: 60px;
  }
  .team_members-heading h1 {
    font-size: 2.5rem;
    text-align: center;
    line-height: 1.2;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 43%, #686868 71%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    margin-bottom: 20px;
  }
  .team_members-heading p {
    font-size: 1rem;
    text-align: center;
    line-height: 1.6;
  }
  .team_members-card {
    background-color: #fff;
    padding: 25px;
    border-radius: 14px;
  }
  .team_members-cards-details {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
  .team_members-content img {
    width: 100%;
  }
  .team_members-content h5 {
    font-size: 18px;
    margin-top: 10px;
  }
  .team_members-content p {
    font-size: 18px;
    padding-bottom: 20px;
    border-bottom: 0.5px solid #aaa;
  }
  .social_Media {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }
  .social_Media p {
    font-size: 20px;
  }
  /* END TEAM MEMBER */

  /* >CURRENTLY */
  .currently_container {
    padding: 20px 20px;
    margin-top: 60px;
  }
  .currently_heading h1 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 51%, #d6d6d6 84%, #c7c7c7);
    text-align: center;
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    margin-bottom: 1rem;
  }
  .currently_heading p {
    font-size: 1rem;
    text-align: center;
    line-height: 1.6;
  }
  .currently_content {
    width: 100%;
    margin-top: 4rem;
  }
  .currenlty_wrappper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .currrent_details {
    padding: 1.5rem;
    background-color: #f5f5f5;
    border-radius: 14px;
  }

  .currently_content h3 {
    font-weight: 600;
    line-height: 1.4;
    font-size: 1.25rem;
    margin-bottom: 16px;
  }
  .location {
    display: flex;
    flex-direction: column;
  }
  .location_box,
  .time_box {
    display: flex;
    gap: 10px;
  }
  .current_desc {
    font-size: 1rem;
  }
  .currently_icons {
    font-size: 23px;
  }
  .carrenltly_roles {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 5rem;
    gap: 10px;
  }
  .carrenltly_roles h6 {
    font-size: 1.125rem;
  }
  /* > END CURRENTLY */
  /* =============================>END ABOUT US STYLING */

  /* ======================>CONTACT */
  .contactInfo_container {
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .contactInfo_content h1 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 44%, #757575 76%, #dfdfdf);
    -webkit-background-clip: text;
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2.5rem;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  .contactInfo_content p {
    margin-bottom: 15px;
  }
  .contant_info-details-desc {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .contactInfo_content-Card h3 {
    margin-top: 10px;
  }
  .contant_info-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .form {
    background-color: #f3f3f3;
    padding: 12px;
    border-radius: 14px;
  }
  .info {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  .info label {
    margin-bottom: 0.25rem;
    font-weight: 500;
    font-size: 16px;
  }
  .info input {
    padding: 14px 15px;
    border-radius: 6px;
    width: 100%;
    color: #333;
    font-size: 14px;
    outline: none;
    background-color: #fff;
    line-height: 1.42857;
  }
  .info input::placeholder {
    font-size: 14px;
    font-family: Poppins, sans-serif;
  }
  .info textarea {
    border-radius: 10px;
  }
  .info textarea::placeholder {
    font-size: 14px;
    padding: 0 16px;
  }
  .text_area {
    padding: 8px;
    font-size: 12px;
  }
  .text_area::placeholder {
    font-size: 10px;
    font-family: Poppins, sans-serif;
    color: #333;
  }
  .form_btn {
    background-color: #6e45e9;
    color: #fff;
    text-align: center;
    border-radius: 200px;
    padding: 0.75rem 1.5rem;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .form_field {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  /* ======================>END +CONTACT */

  /* +=======================>BLOG   */
  .card_box {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 30px;
  }
  .cardImg {
    width: 100%;
    height: 168px;
    object-fit: cover;
  }
  .box_content h1 {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.4;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  .blog_buttons {
    overflow: scroll;
    padding: 0.5rem 3rem;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .blog_btn {
    background-color: transparent;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 500;
  }
  .blog_btn_active {
    border: 1px solid #505050;
  }
  /* ******** */
  .analytics_container {
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 50px;
  }
  .analytics_heading {
    font-size: 2.5rem;
    font-weight: 300;
    margin-bottom: 20px;
  }
  .analytic_card {
    border: 1px solid #eae9e9;
    border-radius: 10px;
  }
  .analytic_card-img img {
    width: 100%;
    height: 369px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .analytic_card-content {
    display: flex;
    flex-direction: column;
  }
  .analytic_body {
    padding: 20px;
  }
  .analytic_btn {
    border-radius: 10px;
    padding: 6px 8px;
    font-size: 16px;
    margin: 13px 0;
  }
  .analytic_card-content-title {
    font-size: 1.5rem;
    line-height: 1.4;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .analytic_card-content-details {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 2.5rem;
  }
  .analytic_card-content-details img {
    width: 45px;
    height: 45px;
  }
  .analytic_card-content-details p {
    font-size: 14px;
  }
  /* +============================>END-->BLOG*/

  /*==============================>FEATURE EDITING */
  .product_contantainer {
    padding: 25px;
  }
  .product_title {
    font-size: 40px;
    text-align: center;
    margin-bottom: 20px;
  }
  .product_subtitle {
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
  }
  .product-btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product-btn {
    background-color: #6e45e9;
    color: #fff;
    font-size: 16px;
  }
  .product-img img {
    margin-top: 50px;
    width: 580px;
    height: 386px;
  }
  /*==============================> END FEATURE EDITING */
  .home {
    margin-top: 93px;
    padding: 0 35px;
  }
  .header {
    padding: 10px 35px;
  }
  .section {
    margin: 5rem 0;
    padding: 0px 38px;
  }
  .demo_section {
    gap: 3rem;
  }
  .home {
    margin-top: 14px;
  }
  .home_content h2 {
    font-size: 2.35rem;
  }
  .home_content p {
    font-size: 1rem;
  }
  .demo_content h1 {
    font-size: 2.5rem;
  }
  .text-size-medium {
    font-size: 14px;
  }
  .demo_content p {
    font-size: 1rem;
  }
  .demo_component video {
    width: 544px;
    height: auto;
  }
  .integrate-heading {
    text-align: center;
    font-size: 2.3rem;
  }
  .integrate-heading {
    padding-top: 19px;
  }
  .integer-content {
    grid-template-columns: 1fr;
    gap: 2.5rem;
    padding: 1rem 0;
  }
  .minimize_content span {
    font-size: 2rem;
  }
  .business_content span {
    font-size: 2rem;
  }
  .client_content span {
    font-size: 2rem;
  }
  .resourses-heading span {
    font-size: 39px;
  }
  .resourses-heading p {
    font-size: 16px;
  }
  .support_content span {
    font-size: 35px;
  }
  .support_content p {
    font-size: 16px;
  }
  .footer {
    padding: 0px 40px;
  }
  .footer_end {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }
  .footer_end ul li {
    display: flex;
    flex-direction: column;
    gap: 13px;
    margin-bottom: 1.5rem;
  }
  .footer_end p {
    font-size: 16px;
    margin-bottom: 2rem;
  }
  .nav_menu {
    display: block;
    color: #fff;
    overflow: hidden;
  }

  /* hide navigation */
  .hide {
    position: absolute;
    top: 16%;
    left: 0;
    width: 100%;
    padding: 28px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 20px;
    transform: translateY(-170vh);
    transition: 1s;
  }

  /* menu */
  .show {
    position: absolute;
    top: 16%;
    left: 0;
    font-size: 20px;
    padding: 28px;
    padding-top: 16px;
    height: 100vh;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 100000;
    transform: translateY(1vh);
    transition: 1s;
  }
}
@media screen and (max-width: 479px) {
  /* ========================>BOOK DEMO */
  .demo_logos {
    margin-bottom: 4rem;
  }
  .spendless_container {
    padding-left: 5%;
    padding-right: 5%;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 5rem;
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
  .spendless_content h1 {
    background-image: linear-gradient(#000 44%, #757575 76%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2.5rem;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  .spendless_desc {
    margin-bottom: 30px;
  }
  .spendless_details {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
  }
  .spendless_details h3 {
    font-size: 1.25rem;
  }
  .spendless_desc p {
    font-size: 1rem;
    line-height: 1.4;
    width: 99%;
  }
  .uniquefeature_container h3 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 43%, #686868 71%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 60px;
  }
  .uniquefeature_container {
    padding-right: 5%;
    padding-left: 5%;
  }
  .uniquefeature_content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-bottom: 5rem;
  }
  .uniquefeature_details {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  .uniquefeature_details h6 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 51%, #d6d6d6 84%, #c7c7c7);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    text-align: center;
    font-size: 1.125rem;
    margin-bottom: 20px;
    margin-top: 30px;
    line-height: 1.4;
  }
  .uniquefeature_content img {
    width: 379px;
    height: 360px;
    padding: 20px;
  }
  .form_field {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  /* ========================>END +BOOK DEMO */

  /* +=========================>PRICING DETAILS */
  .link_box {
    display: none;
  }
  .pd_container {
    padding: 20px 20px;
  }
  .pd_heading {
    text-align: center;
  }
  .pd-title {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 44%, #757575 76%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2.25rem;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  .pd_heading p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 40px;
  }
  .pd_card-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 50px;
  }
  .month_btn,
  .Year-btn {
    border-radius: 7px;
    padding: 0.5rem 1.5rem;
    background-color: transparent;
    font-size: 15px;
  }
  .active_btn {
    background-color: #000;
    color: #fff;
  }
  .card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    background-color: #fff;
    background-image: linear-gradient(330deg, #ececec, #fff 69%);
    border: 1px solid #e1e1e1;
    border-radius: 20px;
    padding: 41px 32px 69px;
    margin-bottom: 15px;
  }
  .card_heading {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .card_img {
    width: 73px;
  }
  .card_titile {
    font-size: 1rem;
    color: #000;
    font-weight: 600;
    font-family: Poppins, sans-serif;
  }
  .card_description-1 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2;
    margin-top: 20px;
  }
  .card_btn {
    border: 1px solid #000;
    font-size: 15px;
    border-radius: 35px;
    padding: 0.75rem 1.5rem;
    background-color: #fff;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    transition: background-color 0.25s;
  }
  .card_description-2 {
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    margin-bottom: 13px;
  }
  .list {
    line-height: 1.5;
  }
  .listItem {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 14px;
  }
  .list_icon {
    font-size: 25px;
  }
  .CPS_container {
    padding: 20px 30px;
  }
  .CPS_heading {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .CPS_heading h2 {
    font-size: 2.25rem;
    text-align: center;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 43%, #686868 71%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    line-height: 1.2;
    margin-bottom: 15px;
  }
  .CPS_eachPlan {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .basic,
  .efficient,
  .team {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .basic_heading,
  .efficient_heading,
  .team_heading {
    font-size: 1.125rem;
    margin-bottom: 16px;
    margin-top: 30px;
  }
  .basic_amount,
  .efficient_amount,
  .team_amount {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 16px;
  }
  .efficient_amount span,
  .team_amount span {
    font-weight: normal;
  }
  .basic_discription,
  .efficient_discription,
  .team_discription {
    display: none;
  }
  .Basic_btn,
  .efficient_btn,
  .team_btn {
    width: 330px;
    padding: 13px 30px;
    font-size: 0.9rem;
    background-color: transparent;
    border: 1px solid black;
  }
  .efficient_btn {
    background-color: #6e45e9;
    color: #fff;
  }
  /* ===>coreFeature */
  .CF_heading {
    margin-bottom: 60px;
  }
  .CF_content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .pricing_feature {
    padding: 0.5rem 0;
    margin-top: 5px;
    grid-area: span 1 / span 3 / span 1 / span 3;
  }
  .pricing_row {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    line-height: 1.5;
    border-bottom: 1px solid #e4e4e4;
    font-weight: bold;
  }

  /* END COMPARE PLANS */
  /* FQA */
  .FQA_container {
    padding-right: 5%;
    padding-left: 5%;
  }
  .FQA_heading {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 40%, #6b6b6b 72%, #dfdfdf);
    -webkit-background-clip: text;
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 50px;
  }
  .FQA_heading span {
    font-weight: bold;
  }
  .FQA_content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .FQA_question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    cursor: pointer;
    font-size: 1rem;
    color: #000;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    padding: 20px 0;
    border-top: 1px solid black;
  }

  .hideContent {
    overflow: hidden;
    transform: translateY(-30px);
    max-height: 0;
    transition: all 0.5s ease-in-out;
  }
  .showContent {
    max-height: 999px;
    transition: all 0.5s ease-in;
    padding-bottom: 30px;
  }
  /* END FQA */
  /* ******************************END PRICING DETAILS */

  /* ==============================>CONTACT */
  .contactInfo_container {
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .contactInfo_content h1 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 44%, #757575 76%, #dfdfdf);
    -webkit-background-clip: text;
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2.5rem;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  .contactInfo_content p {
    margin-bottom: 15px;
  }
  .contant_info-details-desc {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .contactInfo_content-Card h3 {
    margin-top: 10px;
  }
  .contant_info-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .form {
    background-color: #f3f3f3;
    padding: 12px;
    border-radius: 14px;
  }
  .info {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  .info label {
    margin-bottom: 0.25rem;
    font-weight: 500;
    font-size: 16px;
  }
  .info input {
    padding: 16px 15px;
    border-radius: 12px;
    width: 100%;
    color: #333;
    font-size: 14px;
    outline: none;
    background-color: #fff;
    line-height: 1.42857;
  }
  .info input::placeholder {
    font-size: 13px;
    font-family: Poppins, sans-serif;
  }
  .info textarea {
    border-radius: 10px;
  }
  .info textarea::placeholder {
    font-size: 14px;
    padding: 0 16px;
  }
  .text_area {
    padding: 8px;
    font-size: 12px;
  }
  .text_area::placeholder {
    font-size: 10px;
    font-family: Poppins, sans-serif;
    color: #333;
  }
  .form_btn {
    background-color: #6e45e9;
    color: #fff;
    text-align: center;
    border-radius: 200px;
    padding: 0.75rem 1.5rem;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    margin-bottom: 20px;
  }
  /* ===========================>END CONTACT */

  /* ==========================>ABOUT US STYLING */
  /* BETTER PRODUCT */
  .BetterProduct_container {
    background-color: #000;
    background-image: url(https://assets-global.website-files.com/6465ef4…/646614b…_Circles%20Shapes%20Background.png);
  }
  .BetterPofuct_content {
    color: #fff;
  }
  .BetterPofuct_heading {
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .BetterPofuct_heading h1 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#fff, #c7c7c7 67%, #797979);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2.5rem;
    line-height: 1.2;
    margin-bottom: 2rem;
  }
  .BetterPofuct_heading p {
    margin-bottom: 2rem;
  }
  .company_features {
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .company_feature {
    background-color: rgba(124, 124, 124, 0.29);
    border: 1px solid #000;
    border-radius: 16px;
    padding: 28px 21px;
    color: #fff;
  }
  .company_feature-content h2 {
    font-size: 1.5rem;
    line-height: 1.4;
    font-weight: 600;
    margin-bottom: 10px;
  }
  /* END BETTER PRODUCT */

  /* WORK SMATTER */
  .WorkSmatter_container {
    padding: 20px 20px;
  }
  .WorkSmatter_heading {
    text-align: center;
  }
  .WorkSmatter_heading h3 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 44%, #757575 76%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2.5rem;
  }
  .WorkSmatter_heading p {
    font-size: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .workSmatter_info {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .workSmatter_info-facts {
    padding: 26px 34px;
    background-color: #f3f3f3;
    border-radius: 20px;
  }
  .facts_title {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 17px;
  }
  /* ND WORK SMATTER */

  /* TEAM MEMBER*/
  .team_members {
    padding: 20px 20px;
    background-image: linear-gradient(#fff, #ececec);
  }
  .team_members-heading {
    margin-bottom: 60px;
  }
  .team_members-heading h1 {
    font-size: 2.5rem;
    text-align: center;
    line-height: 1.2;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 43%, #686868 71%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    margin-bottom: 20px;
  }
  .team_members-heading p {
    font-size: 1rem;
    text-align: center;
    line-height: 1.6;
  }
  .team_members-card {
    background-color: #fff;
    padding: 25px;
    border-radius: 14px;
  }
  .team_members-cards-details {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .team_members-content img {
    width: 100%;
  }
  .team_members-content h5 {
    font-size: 18px;
    margin-top: 10px;
  }
  .team_members-content p {
    font-size: 18px;
    padding-bottom: 20px;
    border-bottom: 0.5px solid #aaa;
  }
  .social_Media {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }
  .social_Media p {
    font-size: 20px;
  }
  /* END TEAM MEMBER */

  /* >CURRENTLY */
  .currently_container {
    padding: 20px 20px;
    margin-top: 60px;
  }
  .currently_heading h1 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 51%, #d6d6d6 84%, #c7c7c7);
    text-align: center;
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    margin-bottom: 1rem;
  }
  .currently_heading p {
    font-size: 1rem;
    text-align: center;
    line-height: 1.6;
  }
  .currently_content {
    width: 100%;
    margin-top: 4rem;
  }
  .currenlty_wrappper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .currrent_details {
    padding: 1.5rem;
    background-color: #f5f5f5;
    border-radius: 14px;
  }

  .currently_content h3 {
    font-weight: 600;
    line-height: 1.4;
    font-size: 1.25rem;
    margin-bottom: 16px;
  }
  .location {
    display: flex;
    flex-direction: column;
  }
  .location_box,
  .time_box {
    display: flex;
    gap: 10px;
  }
  .current_desc {
    font-size: 1rem;
  }
  .currently_icons {
    font-size: 23px;
  }
  .carrenltly_roles {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 5rem;
    gap: 10px;
  }
  .carrenltly_roles h6 {
    font-size: 1.125rem;
  }
  /* > END CURRENTLY */
  /* ========================>END ABOUT US STYLING */

  /* ==========================>  sign In / sign Out ACCOUNT */
  .signIn_container {
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .login_logo {
    display: flex;
    justify-content: left;
    margin-bottom: 2px;
  }
  .signIn_form_container h1 {
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 10px;
  }
  .signIn_form_container p {
    font-size: 1rem;
    font-family: Poppins, sans-serif;
    margin-bottom: 30px;
  }
  .SignIn_form-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .SignIn_form-content input {
    height: auto;
    min-height: 3.5rem;
    background-color: transparent;
    color: black;
    border: 1px solid #e0e0e0;
    border-radius: 200px;
    padding: 0.5rem 0.75rem 0.5rem 1rem;
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    line-height: 1.6;
    outline: none;
  }
  .lable_link {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .signIn_link {
    text-decoration: underline;
    color: #000;
    cursor: pointer;
  }
  .form_container {
    display: grid;
    gap: 30px;
  }
  .signIN_btns {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .Log_in-btn,
  .sign_in_btn {
    padding: 15px 30px;
  }
  .Log_in-btn {
    background-color: #6e45e9;
    color: #fff;
    font-weight: 500;
  }
  .sign_in_btn {
    border: 1px solid #000;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .sign_in_btn span {
    font-size: 14px;
    font-weight: bold;
  }
  .google_icon {
    font-size: 1.2rem;
  }
  .signIn_img img {
    width: 439px;
    border-radius: 20px;
  }
  .login-link {
    display: flex;
    justify-content: center;
    gap: 4px;
    margin-top: 20px;
  }
  .login-link a {
    text-decoration: underline;
    color: #000;
    cursor: pointer;
  }
  /* *************************> sign In / sign OutEND ACCOUNT */

  /* +=======================>BLOG   */
  .card_box {
    padding: 20px 20px;
  }
  .blog_buttons {
    overflow: scroll;
    padding: 0.5rem 1.5rem;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .blog_btn {
    background-color: transparent;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 500;
  }
  .blog_btn_active {
    border: 1px solid #505050;
  }
  /* ******** */
  .analytics_container {
    padding: 20px 20px;
    margin-bottom: 10px;
  }
  .analytics_heading {
    font-size: 2.5rem;
    font-weight: 300;
    margin-bottom: 20px;
  }
  .analytic_card {
    border: 1px solid #eae9e9;
    border-radius: 10px;
  }
  .analytic_card-img img {
    width: 100%;
    height: 230px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .analytic_card-content {
    display: flex;
    flex-direction: column;
  }
  .analytic_body {
    padding: 20px;
  }
  .analytic_btn {
    border-radius: 10px;
    padding: 6px 8px;
    font-size: 12px;
    margin: 13px 0;
  }
  .analytic_card-content-title {
    font-size: 1.5rem;
    line-height: 1.4;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .analytic_card-content-details {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }
  .analytic_card-content-details img {
    width: 30px;
    height: 30px;
  }
  .analytic_card-content-details p {
    font-size: 11px;
  }
  /* *********************************> END-->BLOG   */

  /* =================================>FEATURE */
  .product_contantainer {
    padding: 25px;
  }
  .product_title {
    font-size: 40px;
    text-align: center;
    margin-bottom: 20px;
  }
  .product_subtitle {
    font-size: 15px;
    text-align: center;
    margin-bottom: 20px;
  }
  .product-btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product-btn {
    background-color: #6e45e9;
    color: #fff;
    font-size: 16px;
  }
  .product-img img {
    margin-top: 50px;
    width: 430px;
    height: 283px;
  }
  /* ************************************> END FEATURE */
  .home {
    margin-top: 93px;
    padding: 0 25px;
  }
  .header {
    padding: 10px 13px;
  }
  .section {
    padding: 0px 22px;
    margin: 2rem 0;
  }
  .hero_section {
    margin-top: -56px;
    padding: 70px 27px;
    height: 100vh;
    background-position: 10%;
    background-position-x: right;
    background-repeat: no-repeat;
    background-size: contain;
    overflow: hidden;
  }
  .home_content h2 {
    font-size: 2.31rem;
  }
  .demo_section {
    margin: 4rem 0;
    padding: 0 26px;
  }
  .demo_component video {
    width: 379px;
    height: auto;
    object-fit: cover;
  }
  .minimize_content span {
    font-size: 2.1rem;
  }
  .minimize_content img {
    width: 403px;
    height: 385px;
  }
  .business_content span {
    font-size: 2.1rem;
  }
  .business_content img {
    width: 403px;
    height: 385px;
  }
  .client_content span {
    font-size: 2.1rem;
  }
  .client_content img {
    width: 403px;
    height: 385px;
  }
  .resourses-heading p {
    font-size: 16px;
    text-align: center;
  }
  .resourses-heading span {
    margin-bottom: 15px;
  }
  .card_box {
    grid-template-columns: 1fr;
  }
  .support_content span {
    font-size: 38px;
  }
  .support_content p {
    font-size: 17px;
  }
  .footer {
    padding: 0px 25px;
  }
  .nav_menu {
    display: block;
    color: #fff;
  }
  /* hide navigation */
  .hide {
    position: absolute;
    top: 16%;
    left: 0;
    width: 100%;
    padding: 28px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 20px;
    transform: translateY(-170vh);
    transition: 1s;
  }

  /* menu */
  .show {
    position: absolute;
    top: 16%;
    left: 0;
    font-size: 20px;
    padding: 28px;
    padding-top: 16px;
    height: 100vh;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 100000;
    transform: translateY(1vh);
    transition: 1s;
  }
}
@media screen and (max-width: 430px) {
  .signIn_img img {
    width: 395px;
    border-radius: 20px;
  }
  /* hide navigation */
  .hide {
    position: absolute;
    top: 16%;
    left: 0;
    width: 100%;
    padding: 28px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 20px;
    transform: translateY(-170vh);
    transition: 1s;
  }
  header {
    position: relative;
    /* overflow: hidden; */
  }
  /* menu */
  .show {
    position: absolute;
    top: 84%;
    left: 0;
    font-size: 20px;
    padding: 28px;
    padding-top: 16px;
    height: 100vh;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 888;
    transform: translateY(1vh);
    transition: 1s;
  }
}

@media screen and (max-width: 414px) {
  /* hide navigation */
  .hide {
    position: absolute;
    top: 16%;
    left: 0;
    width: 100%;
    padding: 28px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 20px;
    transform: translateY(-170vh);
    transition: 1s;
  }
  header {
    position: relative;
    /* overflow: hidden; */
  }
  /* menu */
  .show {
    position: absolute;
    top: 84%;
    left: 0;
    font-size: 20px;
    padding: 28px;
    padding-top: 16px;
    height: 100vh;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 888;
    transform: translateY(1vh);
    transition: 1s;
  }
}
@media screen and (max-width: 375px) {
  .link_box {
    display: none;
  }
  /* ==========================>  sign In / sign Out ACCOUNT */
  .signIn_container {
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .signIn_logo img {
    margin-bottom: 20px;
  }
  .signIn_form_container h1 {
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 10px;
  }
  .signIn_form_container p {
    font-size: 1rem;
    font-family: Poppins, sans-serif;
    margin-bottom: 30px;
  }
  .SignIn_form-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .SignIn_form-content input {
    height: auto;
    min-height: 3.5rem;
    background-color: transparent;
    color: black;
    border: 1px solid #e0e0e0;
    border-radius: 200px;
    padding: 0.5rem 0.75rem 0.5rem 1rem;
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    line-height: 1.6;
    outline: none;
  }
  .lable_link {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .signIn_link {
    text-decoration: underline;
    color: #000;
    cursor: pointer;
  }
  .form_container {
    display: grid;
    gap: 30px;
  }
  .signIN_btns {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .Log_in-btn,
  .sign_in_btn {
    padding: 15px 30px;
  }
  .Log_in-btn {
    background-color: #6e45e9;
    color: #fff;
    font-weight: 500;
  }
  .sign_in_btn {
    border: 1px solid #000;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .sign_in_btn span {
    font-size: 14px;
    font-weight: bold;
  }
  .google_icon {
    font-size: 1.2rem;
  }
  .signIn_img img {
    width: 335px;
    border-radius: 20px;
  }
  .login-link {
    display: flex;
    justify-content: center;
    gap: 4px;
    margin-top: 20px;
  }
  .login-link a {
    text-decoration: underline;
    color: #000;
    cursor: pointer;
  }
  /* *************************>END ACCOUNT */

  /* ========================>BOOK DEMO */
  .spnedless_container {
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .spendless_content h1 {
    background-image: linear-gradient(#000 44%, #757575 76%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2.5rem;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  .spendless_desc {
    margin-bottom: 30px;
  }
  .spendless_details {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 1px;
  }
  .uniquefeature_container h3 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 43%, #686868 71%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 60px;
  }
  .uniquefeature_container {
    padding: 20px 20px;
  }
  .uniquefeature_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4rem;
  }
  .uniquefeature_details {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  .uniquefeature_details h6 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 51%, #d6d6d6 84%, #c7c7c7);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    text-align: center;
    font-size: 1.125rem;
    line-height: 1.4;
  }
  .uniquefeature_content img {
    width: 285px;
    height: 291px;
    padding: 1px;
  }

  /* ========================>END +BOOK DEMO */

  /* ========================>CONTACT */
  .contactInfo_container {
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .contactInfo_content h1 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 44%, #757575 76%, #dfdfdf);
    -webkit-background-clip: text;
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2.5rem;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  .contactInfo_content p {
    margin-bottom: 15px;
  }
  .contant_info-details-desc {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .contactInfo_content-Card h3 {
    margin-top: 10px;
  }
  .contant_info-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .form {
    background-color: #f3f3f3;
    padding: 12px;
    border-radius: 14px;
  }
  .info {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  .info label {
    margin-bottom: 0.25rem;
    font-weight: 500;
    font-size: 16px;
  }
  .info input {
    padding: 14px 16px;
    border-radius: 14px;
    width: 100%;
    color: #333;
    font-size: 14px;
    outline: none;
    background-color: #fff;
    line-height: 1.42857;
  }
  .info input::placeholder {
    font-size: 14px;
    font-family: Poppins, sans-serif;
  }
  .info textarea {
    border-radius: 10px;
  }
  .text_area {
    padding: 8px;
    font-size: 12px;
  }
  .text_area::placeholder {
    font-size: 10px;
    font-family: Poppins, sans-serif;
    color: #333;
  }
  .form_btn {
    background-color: #6e45e9;
    color: #fff;
    text-align: center;
    border-radius: 200px;
    padding: 0.75rem 1.5rem;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    margin-bottom: 20px;
  }
  /* *************************>END CONTACT */

  /* +=======================>BLOG   */
  .card_box {
    padding: 20px 20px;
  }
  .blog_buttons {
    overflow: scroll;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .blog_btn {
    background-color: transparent;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 500;
  }
  .blog_btn_active {
    border: 1px solid #505050;
  }
  /* ******** */
  .analytics_container {
    padding: 20px 20px;
    margin-bottom: 10px;
  }
  .analytics_heading {
    font-size: 2.5rem;
    font-weight: 300;
    margin-bottom: 20px;
  }
  .analytic_card {
    border: 1px solid #eae9e9;
    border-radius: 10px;
  }
  .analytic_card-img img {
    width: 100%;
    height: 230px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .analytic_card-content {
    display: flex;
    flex-direction: column;
  }
  .analytic_body {
    padding: 20px;
  }
  .analytic_btn {
    border-radius: 10px;
    padding: 6px 8px;
    font-size: 12px;
    margin: 13px 0;
  }
  .analytic_card-content-title {
    font-size: 1.5rem;
    line-height: 1.4;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .analytic_card-content-details {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }
  .analytic_card-content-details img {
    width: 30px;
    height: 30px;
  }
  .analytic_card-content-details p {
    font-size: 11px;
  }
  /* ****************************>END-->BLOG   */

  /* ==========================>ABOUT US STYLING */
  /* BETTER PRODUCT */
  .BetterProduct_container {
    background-color: #000;
    background-image: url(https://assets-global.website-files.com/6465ef4…/646614b…_Circles%20Shapes%20Background.png);
  }
  .BetterPofuct_content {
    color: #fff;
  }
  .BetterPofuct_heading {
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .BetterPofuct_heading h1 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#fff, #c7c7c7 67%, #797979);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2.5rem;
    line-height: 1.2;
    margin-bottom: 2rem;
  }
  .BetterPofuct_heading p {
    margin-bottom: 2rem;
  }
  .company_features {
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .company_feature {
    background-color: rgba(124, 124, 124, 0.29);
    border: 1px solid #000;
    border-radius: 16px;
    padding: 28px 21px;
    color: #fff;
  }
  .company_feature-content h2 {
    font-size: 1.5rem;
    line-height: 1.4;
    font-weight: 600;
    margin-bottom: 10px;
  }
  /* END BETTER PRODUCT */

  /* WORK SMATTER */
  .WorkSmatter_container {
    padding: 20px 20px;
  }
  .WorkSmatter_heading {
    text-align: center;
  }
  .WorkSmatter_heading h3 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 44%, #757575 76%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2.5rem;
  }
  .WorkSmatter_heading p {
    font-size: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .workSmatter_info {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .workSmatter_info-facts {
    padding: 26px 34px;
    background-color: #f3f3f3;
    border-radius: 20px;
  }
  .facts_title {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 17px;
  }
  /* ND WORK SMATTER */

  /* TEAM MEMBER*/
  .team_members {
    padding: 20px 20px;
    background-image: linear-gradient(#fff, #ececec);
  }
  .team_members-heading {
    margin-bottom: 60px;
  }
  .team_members-heading h1 {
    font-size: 2.5rem;
    text-align: center;
    line-height: 1.2;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 43%, #686868 71%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    margin-bottom: 20px;
  }
  .team_members-heading p {
    font-size: 1rem;
    text-align: center;
    line-height: 1.6;
  }
  .team_members-card {
    background-color: #fff;
    padding: 25px;
    border-radius: 14px;
  }
  .team_members-cards-details {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .team_members-content img {
    width: 100%;
  }
  .team_members-content h5 {
    font-size: 18px;
    margin-top: 10px;
  }
  .team_members-content p {
    font-size: 18px;
    padding-bottom: 20px;
    border-bottom: 0.5px solid #aaa;
  }
  .social_Media {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }
  .social_Media p {
    font-size: 20px;
  }
  /* END TEAM MEMBER */

  /* >CURRENTLY */
  .currently_container {
    padding: 20px 20px;
    margin-top: 60px;
  }
  .currently_heading h1 {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 51%, #d6d6d6 84%, #c7c7c7);
    text-align: center;
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    margin-bottom: 1rem;
  }
  .currently_heading p {
    font-size: 1rem;
    text-align: center;
    line-height: 1.6;
  }
  .currently_content {
    width: 100%;
    margin-top: 4rem;
  }
  .currenlty_wrappper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .currrent_details {
    padding: 1.5rem;
    background-color: #f5f5f5;
    border-radius: 14px;
  }

  .currently_content h3 {
    font-weight: 600;
    line-height: 1.4;
    font-size: 1.25rem;
    margin-bottom: 16px;
  }
  .location {
    display: flex;
    flex-direction: column;
  }
  .location_box,
  .time_box {
    display: flex;
    gap: 10px;
  }
  .current_desc {
    font-size: 1rem;
  }
  .currently_icons {
    font-size: 23px;
  }
  .carrenltly_roles {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 5rem;
    gap: 10px;
  }
  .carrenltly_roles h6 {
    font-size: 1.125rem;
  }
  /* > END CURRENTLY */
  /* **********************>END ABOUT US STYLING */

  /* +====================>PRICING DETAILS */
  .pd_container {
    padding: 20px 20px;
  }
  .pd_heading {
    text-align: center;
  }
  .pd-title {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 44%, #757575 76%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2.25rem;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  .pd_heading p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 40px;
  }
  .pd_card-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 50px;
  }
  .month_btn,
  .Year-btn {
    border-radius: 7px;
    padding: 0.5rem 1.5rem;
    background-color: transparent;
    font-size: 15px;
  }
  .active_btn {
    background-color: #000;
    color: #fff;
  }
  .card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    background-color: #fff;
    background-image: linear-gradient(330deg, #ececec, #fff 69%);
    border: 1px solid #e1e1e1;
    border-radius: 20px;
    padding: 41px 32px 69px;
    margin-bottom: 15px;
  }
  .card_heading {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .card_img {
    width: 73px;
  }
  .card_titile {
    font-size: 1rem;
    color: #000;
    font-weight: 600;
    font-family: Poppins, sans-serif;
  }
  .card_description-1 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2;
    margin-top: 20px;
  }
  .card_btn {
    border: 1px solid #000;
    font-size: 15px;
    border-radius: 35px;
    padding: 0.75rem 1.5rem;
    background-color: #fff;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    transition: background-color 0.25s;
  }
  .card_description-2 {
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    margin-bottom: 13px;
  }
  .list {
    line-height: 1.5;
  }
  .listItem {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 14px;
  }
  .list_icon {
    font-size: 25px;
  }
  .CPS_container {
    padding: 20px 30px;
  }
  .CPS_heading {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .CPS_heading h2 {
    font-size: 2.25rem;
    text-align: center;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 43%, #686868 71%, #dfdfdf);
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    line-height: 1.2;
    margin-bottom: 15px;
  }
  .CPS_eachPlan {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .basic,
  .efficient,
  .team {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .basic_heading,
  .efficient_heading,
  .team_heading {
    font-size: 1.125rem;
    margin-bottom: 16px;
    margin-top: 30px;
  }
  .basic_amount,
  .efficient_amount,
  .team_amount {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 16px;
  }
  .efficient_amount span,
  .team_amount span {
    font-weight: normal;
  }
  .basic_discription,
  .efficient_discription,
  .team_discription {
    display: none;
  }
  .Basic_btn,
  .efficient_btn,
  .team_btn {
    width: 330px;
    padding: 13px 30px;
    font-size: 0.9rem;
    background-color: transparent;
    border: 1px solid black;
  }
  .efficient_btn {
    background-color: #6e45e9;
    color: #fff;
  }
  /* ===>coreFeature */
  .CF_heading {
    margin-bottom: 60px;
  }
  .CF_content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .pricing_feature {
    padding: 0.5rem 0;
    margin-top: 5px;
    grid-area: span 1 / span 3 / span 1 / span 3;
  }
  .pricing_row {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    line-height: 1.5;
    border-bottom: 1px solid #e4e4e4;
    font-weight: bold;
  }
  /* END COMPARE PLANS */

  /* FQA */
  .FQA_container {
    padding: 20px 30px;
  }
  .FQA_heading {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#000 40%, #6b6b6b 72%, #dfdfdf);
    -webkit-background-clip: text;
    background-clip: text;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 50px;
  }
  .FQA_heading span {
    font-weight: bold;
  }
  .FQA_content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .FQA_question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    cursor: pointer;
    font-size: 1rem;
    color: #000;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    padding: 20px 0;
    border-top: 1px solid black;
  }

  .hideContent {
    overflow: hidden;
    transform: translateY(-30px);
    max-height: 0;
    transition: all 0.5s ease-in-out;
  }
  .showContent {
    max-height: 999px;
    transition: all 0.5s ease-in;
    padding-bottom: 30px;
  }
  /* END FQA */
  /* ******************************END PRICING DETAILS */

  /* feature editing part */
  .product_contantainer {
    padding: 25px;
  }
  .product_title {
    font-size: 30px;
    text-align: center;
    margin-bottom: 20px;
  }
  .product_subtitle {
    font-size: 15px;
    text-align: center;
    margin-bottom: 20px;
  }
  .product-btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product-btn {
    background-color: #6e45e9;
    color: #fff;
    font-size: 16px;
  }
  .product-img img {
    margin-top: 50px;
    width: 306px;
    height: 261px;
  }
  /* END feature editing part */

  /* hide navigation */
  .hide {
    position: absolute;
    top: 16%;
    left: 0;
    width: 100%;
    padding: 28px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 20px;
    transform: translateY(-170vh);
    transition: 1s;
  }

  /* menu */
  .show {
    position: absolute;
    top: 81%;
    left: 0;
    font-size: 20px;
    padding: 28px;
    padding-top: 16px;
    height: 100vh;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 100000;
    transform: translateY(1vh);
    transition: 1s;
  }

  .nav_menu {
    display: block;
    color: #fff;
  }
  .demo_section {
    padding: 0 17px;
  }
  .home_content h2 {
    font-size: rem;
  }
  .home-btn-container {
    display: flex;
  }

  .btn1 {
    font-size: 13px;
  }

  .demo_logos {
    padding: 8px 0;
  }
  .demo_component video {
    width: 341px;
    height: auto;
  }
  .demo_component {
    position: relative;
    height: 209px;
  }
  .minimize_content img {
    width: 326px;
    height: auto;
  }
  .business_content img {
    width: 326px;
    height: auto;
  }
  .client_content img {
    width: 326px;
    height: auto;
  }
  .support_content span {
    font-size: 35px;
  }
  .support_content p {
    font-size: 15px;
  }
  .section {
    padding: 0px 25px;
  }
  .footer_item_links {
    gap: 2rem;
  }
}
/* overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 100;
  transition: all 0.5s;
}
.hidden {
  visibility: hidden;
  opacity: 0;
}
/* styling a modal */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f3f3f3;
  width: 100%;
  max-width: 480px;
  box-shadow: 0 4rem 6rem rgba(0, 0, 0, 0.3);
  z-index: 1000;
  border-radius: 12px;
  transition: transform 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}
.modal--heading {
  padding: 14px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
}
.modal--heading h4 {
  font-size: 27px;
}
.btn--close-modal {
  border: none;
  padding: 0;
  font-size: 37px;
  background: none;
}
.modal p {
  text-align: center;
  padding: 90px;
}
